import React from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import HomeScreen from './screens/home/home.screen';
import DeleteTermScreen from './screens/terms/deleteTerm.screen';
import UcMainScreen from './screens/uc/main.screen';
import SitterScreen from './screens/sitter/sitter.screen';
import SitterWriteMobileScreen from './screens/sitter/sitter_write.mobile.screen';
import SitterWriteDoneMobileScreen from './screens/sitter/sitter_write_done.mobile.screen';
import PartnerMain from './screens/partners/main.screen';
import PartnerMenu from './screens/partners/menu.screen';
import PartnerIntroduce from './screens/partners/introduce.screen';
import PartnerApply from './screens/partners/apply.screen';
import MainScreen from './screens/main/main.screen';

const App = ({...props}) : React.ReactElement => { 

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<MainScreen />} />
        <Route path="/deleteTerm" element={<DeleteTermScreen />} />
        <Route path="/uc" element={<UcMainScreen />} />
        <Route path="/sitter" element={<SitterScreen/>} />
        <Route path="/sitter/write" element={<SitterWriteMobileScreen/>} />
        <Route path="/sitter/done" element={<SitterWriteDoneMobileScreen/>} />
        <Route path="/partner" element={<PartnerMain />} />
        <Route path="/partner/introduce" element={<PartnerIntroduce />} />
        <Route path="/partner/menu" element={<PartnerMenu />} />
        <Route path="/partner/apply" element={<PartnerApply />} />
      </Routes>    
    </div>
  );
}

export default App;