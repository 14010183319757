import React from 'react';
import Footer from '../../components/sitter/mobile/footer.component';
import Content02Component from '../../components/sitter/mobile/content_02.component';
import HeaderComponent from '../../components/sitter/mobile/header.component';
import Content01Component from '../../components/sitter/mobile/content_01.component';
import Content03Component from '../../components/sitter/mobile/content_03.component';
import Content04Component from '../../components/sitter/mobile/content_04.component';
import Content05Component from '../../components/sitter/mobile/content_05.component';
import Content06Component from '../../components/sitter/mobile/content_06.component';
import Content07Component from '../../components/sitter/mobile/content_07.component';
import ContentFooterComponent from '../../components/mobile/content_footer.component';
import { BrowserView, MobileView } from 'react-device-detect';
import Content06BrowserComponent from '../../components/sitter/browser/content_06.browser.component';
import Content07BrowserComponent from '../../components/sitter/browser/content_07.browser.component';
import Content05BrowserComponent from '../../components/sitter/browser/content_05.browser.component';
import Content04BrowserComponent from '../../components/sitter/browser/content_04.browser.component';
import Content03BrowserComponent from '../../components/sitter/browser/content_03.browser.component';
import Content01BrowserComponent from '../../components/sitter/browser/content_01.browser.component';
import Content02BrowserComponent from '../../components/sitter/browser/content_02.browser.component';
import BrowserFooter from '../../components/sitter/browser/footer.browser.component';
import HeaderBrowserComponent from '../../components/sitter/browser/header.browser.component';
import CommonFooter from '../../components/common/footer.component';
import { Helmet } from 'react-helmet';

const SitterScreen = ({...props}) : React.ReactElement => {

    return (
        <div style={{overflow : "hidden"}}>
            <Helmet>
                <title>우리 주변의 펫, 우주펫 시터</title>
                <meta name="우리 주변의 펫, 우주펫 시터" content="우리동네 반려동물 돌봄 커뮤니티"/>
                <meta name="description" content="이웃과 주고받는 펫 돌봄, 강아지 산책 친구, 반려동물 정보교류 등 동네기반 펫 돌봄 커뮤니티"/>
                <meta property="og:type" content="website"/> 
                <meta property="og:title" content="우리 주변의 펫, 우주펫 시터"/>
                <meta property="og:url" content="https://spacepet.site/partner"/>
                <meta property="og:description" content="이웃과 주고받는 펫 돌봄, 강아지 산책 친구, 반려동물 정보교류 등 동네기반 펫 돌봄 커뮤니티"/>
                <meta property="og:image" content="https://nhlfpiauavry9180994.cdn.ntruss.com/web/01.png"/>
                <meta property="og:site_name" content="우리 주변의 펫, 우주펫 시터"/>
            </Helmet>

            <BrowserView>
                <div style={{
                    minWidth : 430,
                    maxWidth : 430,
                    margin : "auto"
                }}>
                    <HeaderBrowserComponent/>
                    <BrowserFooter/>
                    <Content01BrowserComponent/>
                    <Content02BrowserComponent/>       
                    <Content03BrowserComponent/>   
                    <Content04BrowserComponent/>  
                    <Content05BrowserComponent/>
                    <Content06BrowserComponent/>
                    <Content07BrowserComponent/>
                    
                </div>
                <CommonFooter />
                
            </BrowserView>


            <MobileView>
                <HeaderComponent/>
                <Footer/>
                <Content01Component/>
                <Content02Component/>       
                <Content03Component/>   
                <Content04Component/>  
                <Content05Component/>
                <Content06Component/>
                <Content07Component/>
            </MobileView>
        </div>
    )
}

export default SitterScreen