import React from 'react';
import './partner.styles.css'
import PartnerWebHeader from './partner.web.header.component';
import Component01 from './web/component_01';
import Component02 from './web/component_02';
import Component03 from './web/component_03';
import Component04 from './web/component_04';
import Component05 from './web/component_05';
import Component06 from './web/component_06';
import Component07 from './web/component_07';
import Footer from '../common/web_footer';


const PartnerMainWebComponent = ({...props}) : React.ReactElement => {

    return <div className="webScreen">
        <PartnerWebHeader />
        
        <div style={{height : 133.09}}/>

        <Component01 />
        <Component02 />
        <Component03 />
        <Component04 />
        <Component05 />
        <Component06 />
        <Component07 />

        <Footer/>
    </div>
}


export default PartnerMainWebComponent;