import React from 'react';
import "./main_mobile.styles.css";

import Component030101 from '../../../assets/images/main/mobile/component_030101.png';
import Component030102 from '../../../assets/images/main/mobile/component_030102.png';
import Component030103 from '../../../assets/images/main/mobile/component_030103.png';
import Component030201 from '../../../assets/images/main/mobile/component_030201.png';
import Component030202 from '../../../assets/images/main/mobile/component_030202.png';
import Component030203 from '../../../assets/images/main/mobile/component_030203.png';
import Component030301 from '../../../assets/images/main/mobile/component_030301.png';
import Component030302 from '../../../assets/images/main/mobile/component_030302.png';
import Component030303 from '../../../assets/images/main/mobile/component_030303.png';

interface Main03ContentComponentProps {
    data: Array<boolean>;
}
  
const Main03ContentComponent : React.FC<Main03ContentComponentProps> = ({ data }) => {
    
    if (data[0]) {
        return <>
            <div className="component">
                <div style={{height : 30}}/>

                <div className="textContainer01">
                    <span className="componentTitleText">서로 도와주는 이웃 반려인</span>

                    <div style={{height : 32}}/>

                    <span className="semiTitleText">
                        주고받는 돌봄품앗이
                    </span>

                    <div style={{height : 6}}/>

                    <span className="titleText">
                        이웃 반려인들과<br/>
                        돌봄  주고받기
                    </span>

                    <div style={{height : 12}}/>

                    <span className="contentText">
                        여기 품앗이를 원하는 이웃들이 모여있어요.<br/>
                        우리 동네 사람들과 도움을 주고받아 보세요.
                    </span>
                </div>

                <img 
                    src={Component030101}
                    alt="imageComponent"
                    className="componentImage02"
                />
            </div>

            <div className="component01">
                <div className="textContainer01">
                    <div style={{height : 40}}/>

                    <span className="semiTitleText">
                        품앗이 이웃 찾는 방법 
                    </span>

                    <div style={{height : 6}}/>

                    <span className="titleText">
                        우리 동네<br/>
                        품앗이 이웃 찾기
                    </span>

                    <div style={{height : 12}}/>

                    <span className="contentText">
                        먼저 품앗이 이웃을 찾는 글을 작성하고,<br/>
                        우리 동네 품앗이 이웃을 만나보세요!
                    </span>
                </div>

                <img 
                    src={Component030102}
                    alt="imageComponent"
                    className="componentImage02"
                />
            </div>
            
            <div className="component">
                <div className="textContainer01">
                    <div style={{height : 40}}/>

                    <span className="semiTitleText">
                        품앗이 일정 공유
                    </span>

                    <div style={{height : 6}}/>

                    <span className="titleText">
                        품앗이 이웃과<br/>
                        돌봐줄 약속 잡기
                    </span>

                    <div style={{height : 12}}/>

                    <span className="contentText">
                        품앗이 나눌 이웃을 찾으셨나요?<br/>
                        원하는 날짜에 약속을 잡아보세요!
                    </span>
                </div>

                <img 
                    src={Component030103}
                    alt="imageComponent"
                    className="componentImage02"
                />
            </div>       
        </>
    }
    else if (data[1]) {
        return <>
            <div className="component">
                <div style={{height : 30}}/>

                <div className="textContainer01">
                    <span className="componentTitleText">전문성을 갖춘 펫시터</span>

                    <div style={{height : 32}}/>

                    <span className="semiTitleText">
                        우리 동네 전문 이웃시터
                    </span>

                    <div style={{height : 6}}/>

                    <span className="titleText">
                        전문적인 펫시터에게<br/>
                        아이 돌봄 부탁하기
                    </span>

                    <div style={{height : 12}}/>

                    <span className="contentText">
                        전문적으로 돌봐주는 펫시터들이 모여있어요.<br/>
                        심사를 통과한 시터들이니 믿고 부탁해 보세요!
                    </span>
                </div>

                <img 
                    src={Component030201}
                    alt="imageComponent"
                    className="componentImage02"
                />
            </div>

            <div className="component01">
                <div className="textContainer01">
                    <div style={{height : 40}}/>

                    <span className="semiTitleText">
                        돌봄 요청 글 작성
                    </span>

                    <div style={{height : 6}}/>

                    <span className="titleText">
                        이웃들에게<br/>
                        돌봄・산책 요청하기
                    </span>

                    <div style={{height : 12}}/>

                    <span className="contentText">
                        돌봐줄 사람이 필요하다면 도움을 요청해 보세요.<br/>
                        도와줄 수 있는 사람을 더 빨리 찾을 수 있어요.
                    </span>
                </div>

                <img 
                    src={Component030202}
                    alt="imageComponent"
                    className="componentImage02"
                />
            </div>
            
            <div className="component">
                <div className="textContainer01">
                    <div style={{height : 40}}/>

                    <span className="semiTitleText">
                        이웃 시터 찾는 방법 
                    </span>

                    <div style={{height : 6}}/>

                    <span className="titleText">
                        우리 동네<br/>
                        이웃 시터 찾기
                    </span>

                    <div style={{height : 12}}/>

                    <span className="contentText">
                        우주펫에서 원하는 시터를 찾아 연락해 보세요.<br/>
                        <span className="contentBoldText">방문∙위탁</span>이 가능한 시터들이 있답니다.
                    </span>
                </div>

                <img 
                    src={Component030203}
                    alt="imageComponent"
                    className="componentImage02"
                />
            </div>       
        </>
    }
    else {
        return <>
        <div className="component">
            <div style={{height : 30}}/>

            <div className="textContainer01">
                <span className="componentTitleText">체계적인 펫호텔・유치원</span>

                <div style={{height : 32}}/>

                <span className="semiTitleText">
                    펫호텔・유치원 한 눈에 비교
                </span>

                <div style={{height : 6}}/>

                <span className="titleText">
                    원하는 조건의 펫호텔・유치원<br/>
                    한 눈에 찾기
                </span>

                <div style={{height : 12}}/>

                <span className="contentText">
                    우리 동네 펫호텔・유치원을 한 눈에 비교할 수 있어<br/>
                    원하는 조건을 쉽고 빠르게 찾을 수 있어요.
                </span>
            </div>

            <img 
                src={Component030301}
                alt="imageComponent"
                className="componentImage02"
            />
        </div>

        <div className="component01">

            <div className="textContainer01">

                <div style={{height : 40}}/>

                <span className="semiTitleText">
                    요금표 정리
                </span>

                <div style={{height : 6}}/>

                <span className="titleText">
                    깔끔하게 정리된<br/>
                    매장별 요금 비교하기
                </span>

                <div style={{height : 12}}/>

                <span className="contentText">
                    매장별 요금표 비교 너무 복잡하지 않았나요?<br/>
                    우주펫에서 한 눈에 매장 요금 비교해 보세요!
                </span>
            </div>

            <img 
                src={Component030302}
                alt="imageComponent"
                className="componentImage02"
            />
        </div>
        
        <div className="component">
            <div className="textContainer01">
                <div style={{height : 40}}/>

                <span className="semiTitleText">
                    쉽고 빠른 예약 요청
                </span>

                <div style={{height : 6}}/>

                <span className="titleText">
                    간편 문의로<br/>
                    간단하게 예약 요청하기
                </span>

                <div style={{height : 12}}/>

                <span className="contentText">
                    전화로 이것저것 이야기하지 않아도<br/>
                    간단한 정보만으로 10초만에 문의할 수 있어요.
                </span>
            </div>

            <img 
                src={Component030303}
                alt="imageComponent"
                className="componentImage02"
            />
        </div>       
    </>
    }

}

export default Main03ContentComponent