import React from 'react';
import './main_web.styles.css';

import ContentImage from '../../../assets/images/main/web/component-06.png'
import ComponentImage01 from '../../../assets/images/main/web/component-04.png';
import ComponentImage02 from '../../../assets/images/main/web/component-05.png';


const MainWeb05Component = ({...props}) : React.ReactElement => {

    return (
        <>  

            <div style={{position : 'relative'}}>
                <img src={ContentImage} alt="" className="webImage05"/>

                <span className='webAbsoulteTitleText'>
                    우주펫 더 알아보기
                </span>
            </div>
                              
            <div className='webComponent03'>
                <div style={{height : 72}}/>
                <div className="webRowContainer01">
                    <div className="webTextContainer01">
                        <div style={{height : 114}}/>
    
                        <span className="webSemiTitleText">
                            산책친구
                        </span>
    
                        <div style={{height : 6}}/>
    
                        <span className="webTitleText">
                            우리아이와 잘 맞는<br/>
                            산책친구 만나기
                        </span>
    
                        <div style={{height : 16}}/>
    
                        <span className="webContentText">
                            우주펫에서 산책 친구를 만나보세요.<br/>
                            거리∙시간대∙보호자 등에 맞게 원하는 친구를 찾을 수 있어요.
                        </span>
                    </div>
    
                    <img 
                        src={ComponentImage01}
                        alt="imageComponent"
                        className="webImage04"
                    />
                </div>
                <div style={{height : 72}}/>
            </div>

            <div className='webComponent02'>
                <div style={{height : 72}}/>
                <div className="webRowContainer01">
                    <div className="webTextContainer01">
                        <div style={{height : 114}}/>

                        <span className="webSemiTitleText">
                            응가챌린지
                        </span>

                        <div style={{height : 6}}/>

                        <span className="webTitleText">
                            반려동물 배변용품<br/>
                            쓴 만큼 돌려받기
                        </span>

                        <div style={{height : 16}}/>

                        <span className="webContentText">
                        사용한&nbsp;배변용품을&nbsp;인증하고&nbsp;쓴&nbsp;만큼&nbsp;받아보세요.<br/>
                        우주펫이&nbsp;집&nbsp;앞으로&nbsp;배변용품을&nbsp;보내드립니다!
                        </span>
                    </div>

                    <img 
                        src={ComponentImage02}
                        alt="imageComponent"
                        className="webImage04"
                    />
                </div>

                <div style={{height : 72}}/>
            </div>



        </>

    )
}

export default MainWeb05Component