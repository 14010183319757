import React from 'react';
import '../partner.styles.css'
import PartnerHeader from '../mobile/mobile.header.component';
import axios from 'axios';

const PhoneComponent = ({...props}) : React.ReactElement => {

    const [invalid, setInvalid] = React.useState({
        phoneNumber : false,
    })
    const [values, setvalues] = React.useState({
        phoneNumber : "",
    });

    const onChangeText = (event : any) => {
        if (event.target.value.length > event.target.maxLength && event.target.maxLength !== -1) {
            event.target.value = event.target.value.slice(0, event.target.maxLength);
        }        

        const {value, name: inputName} = event.target;
        setvalues({...values, [inputName] : value})
    }

    const onChangeInvalid = (event : any) => {
        const { name : inputName } = event.target;
        setInvalid({...invalid, [inputName] : true})
    }

    const onApply = () => {        
        var url = "https://hooks.slack.com/services/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"
        var data = JSON.stringify({
            "text" : values.phoneNumber
        })

        axios.post(url,data)
            .then((result) => {
                window.open("https://spacepetpartner.page.link/event");
            })
    }

    return <div className="phoneApplyScreen">

        <PartnerHeader />
        
        <span className='introTitleTxt'>
            사장님의 
            <span style={{color : "#8B4DFF"}}> 핸드폰 번호</span>를<br/>
            입력해 주세요.
        </span>

        <div style={{height : "40px"}}/>

        <span className="phoneSemiTitle">
            입력하신 번호로 '우주펫 파트너'의<br/>
            안내서를 보내드릴게요!
        </span>

        <div style={{height : "30px"}}/>

        <input        
            name={"phoneNumber"} 
            value={values.phoneNumber}
            inputMode="text"            
            onChange={(e) => onChangeText(e)}
            placeholder='휴대폰 번호를 입력해 주세요.'
            className="customInput"
            onClick={(e) => onChangeInvalid(e)}          
        />

        <div style={{height : "30px"}}/>

        <div className="partnerPhonebtn" onClick={() => onApply()}>
            <span className="partnerPhoneTxt">전송</span>
        </div>
    </div>
}


export default PhoneComponent;