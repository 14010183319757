import React from 'react';
import '../partner.styles.css'
import { useNavigate } from 'react-router-dom';

const Footer = ({...props}) : React.ReactElement => {

    const navigate = useNavigate()  

    const onStart = () => {
       //navigate("/partner/apply")
       window.open("https://spacepetpartner.page.link/event");
    }

    return <div className="footerCon02" onClick={() => onStart()}>
        <p className="footerTxt">
            매장 무료로 등록하기
        </p>
    </div>
}


export default Footer;