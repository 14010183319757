import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { HelmetProvider } from 'react-helmet-async';
import ScrollTop from './model/scroll.model';

const container = document.getElementById('root') as HTMLElement;
const root = ReactDOM.createRoot(container);

if (container.hasChildNodes()) {
  ReactDOM.hydrateRoot(
    container,
    <CookiesProvider>
      <BrowserRouter>
        <ScrollTop />
        <React.StrictMode>
          <HelmetProvider>
            <App />
          </HelmetProvider>
        </React.StrictMode>
      </BrowserRouter>      
    </CookiesProvider>
  )
}
else {
  root.render(
    <CookiesProvider>
      <BrowserRouter>
        <ScrollTop />
        <React.StrictMode>
          <HelmetProvider>
            <App />
          </HelmetProvider>
        </React.StrictMode>
      </BrowserRouter>      
    </CookiesProvider>
  )
}


reportWebVitals();
