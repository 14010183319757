import React from 'react';
import ComponentImage01 from '../../../assets/images/main/web/component-01.png';
import "./main_web.styles.css";

import Logo from '../../../assets/icon/common/logo_color.svg';

const MainWeb01Component = ({...props}) : React.ReactElement => {

    return (
       <>
        <div className="webComponent">

                <div style={{height : "15vh"}}/>

            <div className="webRowContainer03">
                <img 
                    src={Logo}
                    alt=""
                    className="webImage09"
                />

                <div className="webRowContainer04">
                    <span className="webTitleButtonText">
                        이웃시터 지원하기
                    </span>

                    <div style={{width : 10}}/>

                    <span className="webTitleButtonText">
                        파트너 등록하기
                    </span>
                </div>
            </div>

            <div className="webRowContainer01">               
                <div className="webTextContainer01">

                    <div style={{height : 70}}/>

                    <span className="webTitleText">
                        우리 아이 돌봐줄 사람<br/>
                        여기 다 있어요!
                    </span>

                    <div style={{height : 12}}/>

                    <span className="webContentText">
                        출장, 여행 등으로 강아지 고양이<br/>
                        돌봐줄 사람 필요할 때 쉽고 빠르게 찾아보세요!
                    </span>

                    <div style={{height : 27}}/>

                    <span className="webContentMainColorBoldText">
                        #반려인  #펫시터  #펫호텔∙유치원
                    </span>
                </div>

                <img
                    src={ComponentImage01}
                    alt="컴포넌트 이미지"
                    className="webImage01"
                />
            </div>
        </div>
       </>
    )
}

export default MainWeb01Component