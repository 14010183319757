import React from 'react';
import ComponentImage02 from '../../../assets/images/main/mobile/component_02.png';
import "./main_mobile.styles.css";

const Main02Component = ({...props}) : React.ReactElement => {

    return (
       <>
        <div className="component">

            <div style={{height : 50}}/>

            <div className='textContainer01'>

                <span className="semiTitleText">
                    반려인을 돕는 이웃
                </span>

                <div style={{height : 6}}/>

                <span className="titleText">
                    우리 동네 돌봄 이웃에게<br/>
                    도움 받아보기
                </span>

                <div style={{height : 12}}/>

                <span className="contentText">
                    반려동물을 키우다 누군가의 도움이 필요할 때,<br/>
                    <span className="contentBoldText">돌봄∙산책</span>을 부탁할 이웃을 찾아보세요.
                </span>
            </div>

            <div style={{height : 37}}/>

            <img 
                src={ComponentImage02}
                alt="imageComponent"
                className="componentImage02"
            />
        </div>
       </>
    )
}

export default Main02Component