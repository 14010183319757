import React from 'react';
import { isIOS } from 'react-device-detect';
import './main_mobile.styles.css'
import { AppStoreLink, PlayStoreLink } from '../../../data/urls';

const MainFooter = ({...props}) : React.ReactElement => {

    const onStart = () => {
        if (isIOS) {
            window.open(AppStoreLink);
        }
        else {
            window.open(PlayStoreLink);
        }
    }

    return <div className="footerButton" onClick={() => onStart()}>
        돌봄 부탁하기
    </div>
}


export default MainFooter;