import React from 'react';

import ContentImage from '../../../assets/image/mobile/content/home_content_download.png'
import Apple from '../../../assets/icon/component/download/apple.svg';
import Google from '../../../assets/icon/component/download/google.svg';

import './main_mobile.styles.css';
import { AppStoreLink, PlayStoreLink} from '../../../data/urls';

const MainDownloadComponent = ({...props}) : React.ReactElement => {

    const pressApple = () => {
        window.open(AppStoreLink, "_blank")        
    }

    const pressGoogle = () => {
        window.open(PlayStoreLink,  "_blank")
    }

    return (
        <div className='component04'>

            <div style={{height : 40}}/>
            
            <div className='textContainer01'>
                <span className='downloadTitleText'>
                    우리 아이 돌봐줄
                </span>

                <span className='downloadTitleText'><span className='downloadTitleBoldText'>집 근처 견주/집사님</span>을</span>
                <span className='downloadTitleText'>
                    지금 바로 만나보세요
                </span>
            </div>

            <div style={{height : 30}}/>
            

            <div className="component05">
                <img src={Apple} alt="애플다운로드" className="downloadButton" onClick={() => pressApple()}/>
                <img src={Google} alt="구글다운로드" className="downloadButton" onClick={() => pressGoogle()}/>
            </div>

            <div style={{height : 40}}/>
            
            <img 
                src={ContentImage}
                alt="imageComponent"
                className="componentImage02"
            />
        </div>
    )
}

export default MainDownloadComponent