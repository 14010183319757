import React from 'react';
import './main_web.styles.css';

import Logo from '../../../assets/icon/common/main_web_logo.svg';
import { AppStoreLink, PlayStoreLink } from '../../../data/urls';
import { isIOS } from 'react-device-detect';

const MainWebHeader = ({...props}) : React.ReactElement => {

    const onStart = () => {
        if (isIOS) {
            window.open(AppStoreLink);
        }
        else {
            window.open(PlayStoreLink);
        }
    }

    return <div className="webHeader">
        <img 
            src={Logo}
            alt=""
            className="webImage10"
        />

        <div className="headerButton" onClick={() => onStart()}>
            <span className="headerButtonText">앱 다운로드</span>
        </div>
    </div>
}

export default MainWebHeader;