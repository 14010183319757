import React from 'react';
import { BrowserView, MobileView, TabletView } from 'react-device-detect';
import PartnerMainMobileComponent from '../../components/partner/partner.main.mobile.component';
import { Helmet } from 'react-helmet';
import PartnerMainWebComponent from '../../components/partner/partner.main.web.component';
import PartnerMainTabletComponent from '../../components/partner/partner.main.tablet.component';


const PartnerMain = ({...props}) : React.ReactElement => {

    return (
        <>
            <Helmet>
                <title>우주펫 파트너</title>
                <meta name="우주펫 파트너" content="펫 호텔・유치원 마케팅 1등 공신! 펫 호텔・유치원을 찾는 고객들을 지금 무료로 만나보세요."/>
                <meta name="description" content="펫 호텔・유치원 마케팅 1등 공신! 펫 호텔・유치원을 찾는 고객들을 지금 무료로 만나보세요."/>
                <meta property="og:type" content="website"/> 
                <meta property="og:title" content="우주펫 파트너"/>
                <meta property="og:url" content="https://spacepet.site/partner"/>
                <meta property="og:description" content="펫 호텔・유치원 마케팅 1등 공신! 펫 호텔・유치원을 찾는 고객들을 지금 무료로 만나보세요."/>
                <meta property="og:image" content="https://nhlfpiauavry9180994.cdn.ntruss.com/web/01.png"/>
                <meta property="og:site_name" content="우주펫 파트너"/>
            </Helmet>

            <div style={{overflow : "hidden"}}>
                <BrowserView>
                    <PartnerMainTabletComponent/>
                </BrowserView>
            
                <TabletView>
                    <PartnerMainWebComponent/>
                </TabletView>

                <MobileView>
                    <PartnerMainMobileComponent/>
                </MobileView>
            </div>
        </>

    )
}

export default PartnerMain