import React from 'react';
import './main_web.styles.css';

import Apple from '../../../assets/icon/component/download/apple.svg';
import Google from '../../../assets/icon/component/download/google.svg';
import ContentImage from '../../../assets/image/mobile/content/home_content_download.png'
import { AppStoreLink, PlayStoreLink } from '../../../data/urls';

const MainWebDownloadComponent = ({...props}) : React.ReactElement => {

    const pressApple = () => {
        window.open(AppStoreLink, "_blank")
    }

    const pressGoogle = () => {
        window.open(PlayStoreLink,  "_blank")
    }

    return <div className="webComponent08">

        <div className="webColContainer01">
            <span className="webDownloadTitleText">
                우리 아이 돌봐줄<br/>
                <span className="webDownloadSemiTitleText">집 근처 견주/집사님</span>을<br/>
                지금 바로 만나보세요
            </span>

            <div style={{height : 40}}/>

            <div className="webRowContainer01">
                <img 
                    src={Apple}
                    alt=""
                    className="webImage08"
                    onClick={() => pressApple()}
                />

                <div style={{width : 25}}/>

                <img 
                    src={Google}
                    alt=""
                    className="webImage08"
                    onClick={() => pressGoogle()}
                />
            </div>

        </div>

        <img 
            src={ContentImage}
            alt="imageComponent"
            className="webImage07"
        />
    
    </div>
}

export default MainWebDownloadComponent;