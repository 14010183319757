import React from 'react';
import AliceCarousel from 'react-alice-carousel';

import "./main_web.styles.css";


import Image01 from '../../../assets/images/main/web/component-0401.png';
import Image02 from '../../../assets/images/main/web/component-0402.png';
import Image03 from '../../../assets/images/main/web/component-0403.png';
import Image04 from '../../../assets/images/main/web/component-0404.png';
import Image05 from '../../../assets/images/main/web/component-0405.png';



const Main04Component = ({...props}) : React.ReactElement => {

    const [isDragging, setIsDragging] = React.useState(false);
    const [mouseDown, setMouseDown] = React.useState(false);
    const [paddingLeft, setPaddingLeft] = React.useState(9.375); // default for larger screens

    const updatePadding = () => {
        const screenWidth = window.innerWidth;
        if (screenWidth <= 1440) {
          // 6.1111vw를 숫자로 계산
          setPaddingLeft((screenWidth * 6.1111) / 100);
        } else {
          // 9.3750vw를 숫자로 계산
          setPaddingLeft((screenWidth * 9.3750) / 100);
        }
      };
  
    React.useEffect(() => {
      updatePadding(); // initial padding calculation
      window.addEventListener('resize', updatePadding); // update padding on window resize
  
      return () => {
        window.removeEventListener('resize', updatePadding); // clean up listener
      };
    }, []);

     // 드래그 시작
    const handleMouseDown = () => {
        setMouseDown(true);  // 마우스가 눌렸을 때
        setIsDragging(false); // 드래그 상태 초기화
    };


    // 마우스 버튼을 놓을 때
    const handleMouseUp = (e : any) => {
        if (isDragging) {
            e.preventDefault();  // 드래그 중에는 클릭이 발생하지 않도록 방지
            setTimeout(() => {
                setIsDragging(false); // 드래그 상태 초기화
            }, 500)
        }
        setMouseDown(false); // 마우스를 놓으면 마우스 상태 초기화
    };
    // 클릭 이벤트
    const handleClick = (e : any, index : number) => {

        if (isDragging) {
            console.log('드래그 중에 클릭이 발생했습니다. 실행되지 않음.');
            return;  // 클릭 이벤트를 막는다
        }

        console.log('클릭 이벤트 실행');

        if (index === 0) {
            window.open("https://blog.naver.com/spacepetofficial/222910857769",  "_blank")
        }
        else if (index === 1) {
            window.open("https://blog.naver.com/spacepetofficial/222929495479",  "_blank")
        }
        else if (index === 2) {
            window.open("https://blog.naver.com/spacepetofficial/222916631717",  "_blank")
        }
        else if (index === 3) {
            window.open("https://blog.naver.com/spacepetofficial/222929495259",  "_blank")
        }
        else if (index === 4) {
            window.open("https://blog.naver.com/spacepetofficial/222922143581",  "_blank")
        }
    };

    const dragStart = (e : any) => {
        setIsDragging(true);
        e.preventDefault();
    }

    const items = [        
        <img         
            src={Image01} 
            alt="" 
            onMouseDown={handleMouseDown} 
            onMouseUp={handleMouseUp} 
            onClick={(e) => handleClick(e, 0)} 
            onDragStart={dragStart}         
            className="webImage06"
        />,
        <img         
            src={Image02} 
            alt="" 
            onMouseDown={handleMouseDown} 
            onMouseUp={handleMouseUp} 
            onClick={(e) => handleClick(e, 0)} 
            onDragStart={dragStart}         
            className="webImage06"
        />,
        <img         
            src={Image03} 
            alt="" 
            onMouseDown={handleMouseDown} 
            onMouseUp={handleMouseUp} 
            onClick={(e) => handleClick(e, 0)} 
            onDragStart={dragStart}         
            className="webImage06"
        />,
        <img         
            src={Image04} 
            alt="" 
            onMouseDown={handleMouseDown} 
            onMouseUp={handleMouseUp} 
            onClick={(e) => handleClick(e, 0)} 
            onDragStart={dragStart}         
            className="webImage06"
        />,
        <img         
            src={Image05} 
            alt="" 
            onMouseDown={handleMouseDown} 
            onMouseUp={handleMouseUp} 
            onClick={(e) => handleClick(e, 0)} 
            onDragStart={dragStart}         
            className="webImage06"
        />,
    ];    

    const onMore = () => {
        window.open("https://blog.naver.com/spacepetofficial",  "_blank")
    }

    console.log(isDragging);

    return (
        <div className="webComponent04">
            
            <div className="webTextContainer02">
                <span className="webTitleText">
                    우주펫 유저들의 후기
                </span>
            </div>

            <div style={{height : 75}}/>

            <AliceCarousel
                autoHeight
                mouseTracking 
                disableDotsControls
                disableButtonsControls                
                autoWidth
                items={items}
                paddingLeft={paddingLeft}
                paddingRight={paddingLeft}
            />

            <div style={{height : 65}}/>

            <div className="webButtonContainer02">
                <div 
                    className="webSelectButtonTrue"
                    onClick={() => onMore()}
                >
                    더 많은 후기 보러 가기
                </div>
            </div>

            <div style={{height : 68}}/>
        </div>
    )
}

export default Main04Component