import React from 'react';
import ComponentImage from '../../../assets/images/main/web/component-02.png';
import "./main_web.styles.css";


const MainWeb02Component = ({...props}) : React.ReactElement => {

    return (
       <>
        <div className="webComponent">

            <div style={{height : 120}}/>

            <div className="webRowContainer01">
               
                <div className="webTextContainer01">

                    <div style={{height : 170}}/>

                    <span className="webSemiTitleText">
                        반려인을 돕는 이웃
                    </span>

                    <div style={{height : 6}}/>

                    <span className="webTitleText">
                        우리 동네 돌봄 이웃에게<br/>
                        도움 받아보기
                    </span>

                    <div style={{height : 12}}/>

                    <span className="webContentText">
                        반려동물을 키우다 누군가의 도움이 필요할 때,<br/>
                        <span className="webContentBoldText">돌봄∙산책</span>을 부탁할 이웃을 찾아보세요.
                    </span>
                </div>

                <img
                    src={ComponentImage}
                    alt="컴포넌트 이미지"
                    className="webImage02"
                />
            </div>
        </div>
       </>
    )
}

export default MainWeb02Component