import React from 'react';
import '../partner.styles.css'

import TitleLogo from '../../../assets/icon/partner/logo.svg';

const Component01 = ({...props}) : React.ReactElement => {

    return <div className="partnerIntroScreen01">
        
        <img src={TitleLogo} className='introTitle' alt=""/>
        
        <span className='introTitleTxt'>
            <span style={{color : "#8B4DFF"}}>펫호텔・유치원</span>과<br/>
            돌봄이 필요한 반려인을<br/>
            연결해 드립니다.<br/>
        </span>

        <div></div>
    
    </div>
}


export default Component01;