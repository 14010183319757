import React from 'react';
import ComponentImage01 from '../../../assets/images/main/mobile/component_01.png';
import "./main_mobile.styles.css";


const Main01Component = ({...props}) : React.ReactElement => {

    return (
       <>
        <div className="component">

            <div style={{height : "10.7448vh"}}/>

            <div className='textContainer01'>
                <span className="titleText">
                    우리 아이 돌봐줄 사람<br/>
                    여기 다 있어요!
                </span>

                <div style={{height : 12}}/>

                <span className="contentText">
                    출장, 여행 등으로 강아지 고양이<br/>
                    돌봐줄 사람 필요할 때 쉽고 빠르게 찾아보세요!
                </span>

                <div style={{height : 6}}/>

                <span className="contentMainColorBoldText">
                    #반려인  #펫시터  #펫호텔∙유치원
                </span>
            </div>

            <div style={{height : 6}}/>

            <img 
                src={ComponentImage01}
                alt="imageComponent"
                className="componentImage01"
            />

        </div>
       </>
    )
}

export default Main01Component