import React from 'react';
import '../partner.styles.css'
import { CDN } from '../../../keys';

const Component03 = ({...props}) : React.ReactElement => {

    return <div className='partnerIntroScreen03'>

        <div className='introTxtCon'>
            <p className="grdientTxt04">
                파트너 매장을 위한 혜택
            </p>

            <div style={{height : "1.8750vw"}}/>

            <p className="titleTxt02">
                우리 매장 상단 노출
            </p>

            <div style={{height : "2.5000vw"}}/>

            <p className="contentTxt">
                파트너 매장은 ‘호텔・유치원 찾기' 리스트 가장 상단에 위치해 연락받을 확률이 높아져요.
            </p>
        </div>

        <img src={`${CDN}web/partner/mobile/image_004.png`} alt="" className="componentImg"/>
               
    
    </div>
}


export default Component03;