import React from 'react';
import "./main_web.styles.css";

interface Main03TitleComponentProps {
    data: Array<boolean>;
    onUpdate: (newData: Array<boolean>) => void;
  }
  
  const MainWeb03TitleComponent : React.FC<Main03TitleComponentProps> = ({ data, onUpdate }) => {
    
    const onButton = (index: number) => {
        const newValue = [false, false, false];
        newValue[index] = true;
        onUpdate(newValue); // 상위 컴포넌트에 상태 변경 전달
    };

    return (
       <>
        <div className="webComponent">

            <div style={{height : 88}}/>

            <div className='webTextContainer01'>
                <span className="webTitleText">
                    돌봄 이웃
                </span>                
            </div>

            <div style={{height : 18}}/>

            <div className="webButtonContainer01">
                <div 
                    className={(data[0])? "webSelectButtonTrue" : "webSelectButtonFalse"}
                    onClick={() => onButton(0)}
                >
                    품앗이 이웃
                </div>
                <div 
                    className={(data[1])? "webSelectButtonTrue" : "webSelectButtonFalse"}
                    onClick={() => onButton(1)}
                >
                    이웃 펫시터
                </div>
                <div 
                    className={(data[2])? "webSelectButtonTrue" : "webSelectButtonFalse"}
                    onClick={() => onButton(2)}
                >
                    펫호텔∙유치원
                </div>
            </div>

            <div style={{height : 90}}/>
        </div>
       </>
    )
}

export default MainWeb03TitleComponent