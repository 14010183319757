import React from 'react';
import '../partner.styles.css'
import { CDN } from '../../../keys';

const Component04 = ({...props}) : React.ReactElement => {

    return <div className='partnerIntroScreen04'>

        <div className='introTxtCon'>
            <p className="titleTxt02">
                자동 알림톡 발송
            </p>

            <div style={{height : "2.5000vw"}}/>

            <p className="contentTxt">
                그동안 고객님에게 직접 연락하셨나요?<br/>
                우주펫에 예약 등록하면 사장님 대신<br/>
                자동으로 알림톡을 보내드려요.
            </p>
        </div>

        <img src={`${CDN}web/partner/mobile/image_005.png`} alt="" className="componentImg"/>
               
    
    </div>
}


export default Component04;