import React from 'react';
import './main_web.styles.css';

import QuestionColor from '../../../assets/icon/mobile/faq/quertion_color.svg';
import Question from '../../../assets/icon/mobile/faq/question.svg';

const MainWebQnaComponent = ({...props}) : React.ReactElement => {

    const [data, setData] = React.useState<Array<boolean>>([false, false, false, false]);
    const titles = [
        "돌봄품앗이가 뭔가요?",
        "우주펫은 무료서비스인가요?",
        "현재 오픈된 지역은 어디인가요?",
        "반려동물을 키운 경험이 있거나, 본가에 반려동물이 있는데 서비스 이용이 가능한가요?"
    ]
    const contents = [
        "반려동물 돌봄이 필요할 때, 우리동네 견주/집사님과 돌봄을 주고 받는 것을 의미해요. 우주펫에서 친하게 지낼 이웃 반려인을 찾아보세요.",
        "우주펫의 품앗이 이웃 찾기는 무료 서비스에요. 부담없이 우리동네 반려인들과 소통을 시작해보세요.",
        "우주펫은 전국 어디서든 이용할 수 있어요.",
        "네, 현재 반려동물을 키우고 있지 않더라도 반려동물을 키운 경험만 있다면 우주펫 가입이 가능해요."
    ]

    const onQna = (index : number) => {
        setData((prev) => prev.map((value, idx) => (idx === index? !value: value)));
    }

    return <div>

        <div className="webComponent07">

            <div style={{height : 90}}/>

            <div className="webTextContainer01">
               <span className="webTitleText">자주 묻는 질문</span>
            </div>

            <div style={{height : 60}}/>

            {
                data.map((value, index, array) => {
                    return <div style={{width : "100%"}}onClick={() => onQna(index)}>

                        <div className="webRowContainer02">
                            <img 
                                src={value? QuestionColor : Question}
                                alt=""
                                className="webQuestionIcon"
                            />     

                            <span className={(value)? "webQnaTitleTextTrue" : "webQnaTitleTextFalse"}>
                                {titles[index]}
                            </span>                   
                        </div>

                        {
                            value? 
                                <div className="webQnaComponent">
                                    <span className="webQnaContentText">
                                        {contents[index]}
                                    </span>
                                </div>
                                :
                                <div className="webQnaDivider"></div>
                        }

                     

                                            
                    </div>
                })
            }

            <div style={{height : 100}}/>

        </div>

    
    
    </div>
}

export default MainWebQnaComponent;