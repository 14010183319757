import React from 'react';
import './main_mobile.styles.css';
import 'react-alice-carousel/lib/alice-carousel.css';

import AliceCarousel from 'react-alice-carousel';
import ContentImage01 from '../../../assets/image/mobile/content/06/home_content_06_01.png'
import ContentImage02 from '../../../assets/image/mobile/content/06/home_content_06_02.png'
import ContentImage03 from '../../../assets/image/mobile/content/06/home_content_06_03.png'
import ContentImage04 from '../../../assets/image/mobile/content/06/home_content_06_04.png'
import ContentImage05 from '../../../assets/image/mobile/content/06/home_content_06_05.png'

const Main04Component = ({...props}) : React.ReactElement => {

    const items = [
        <img src={ContentImage01} alt="컨텐츠01" className="componentImage03" onClick={() => onItem(0)}/>,
        <img src={ContentImage02} alt="컨텐츠02" className="componentImage03" onClick={() => onItem(1)}/>,
        <img src={ContentImage03} alt="컨텐츠03" className="componentImage03" onClick={() => onItem(2)}/>,
        <img src={ContentImage04} alt="컨텐츠04" className="componentImage03" onClick={() => onItem(3)}/>,
        <img src={ContentImage05} alt="컨텐츠05" className="componentImage03" onClick={() => onItem(4)}/>,
    ];

    const onMore = () => {
        window.open("https://blog.naver.com/spacepetofficial",  "_blank")
    }
    
    const onItem = (index : number) => {
        var links = [
            "https://blog.naver.com/spacepetofficial/222910857769",
            "https://blog.naver.com/spacepetofficial/222929495479",
            "https://blog.naver.com/spacepetofficial/222916631717",
            "https://blog.naver.com/spacepetofficial/222929495259",
            "https://blog.naver.com/spacepetofficial/222922143581"
        ];

        window.open(links[index],  "_blank")
    }
    
    return (
        <div className='component02'>

            <div className="textContainer01">
                <span className="titleText">
                    우주펫 이웃들의 후기
                </span>
            </div>

            <div style={{height : 40}}/>

            <AliceCarousel 
                autoHeight
                mouseTracking 
                disableDotsControls
                disableButtonsControls                    
                autoWidth
                items={items}
                paddingLeft={27.5}
                paddingRight={27.5}
                autoPlay                
                autoPlayInterval={4000}
            />

            <div style={{height : 25}}/>

            <div className="centerContainer">
                <div className="button01" onClick={() => onMore()}>
                    <span className="buttonText01">더 많은 후기 보러 가기</span>
                </div>  
            </div>                      
        </div>
    )
}

export default Main04Component