import React from 'react';
import '../partner.styles.css'
import { CDN } from '../../../keys';

const Component02 = ({...props}) : React.ReactElement => {

    return <div className='partnerIntroScreen02'>

        <div>
            <p className="grdientTxt03">
                우주펫 파트너가 되면
            </p>

            <div style={{height : "12.5000vw"}}/>

            <p className="titleTxt02">
                반려인에게 직접 제안
            </p>

            <div style={{height : "2.5000vw"}}/>

            <p className="contentTxt">
                돌봄 요청한 고객에게 직접 제안하세요!<br/>
                파트너는 돌봄이 필요한 반려인에게<br/>
                먼저 돌봄을 제안할 수 있어요
            </p>
        </div>

        <img src={`${CDN}web/partner/mobile/image_002.png`} alt="" className="componentImg"/>
        
        
    
    </div>
}


export default Component02;