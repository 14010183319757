import React from 'react';
import '../partner.styles.css'
import Component01 from './component_01';
import Component02 from './component_02';
import Component03 from './component_03';
import Component04 from './component_04';
import Component05 from './component_05';
import Footer from './footer';

const PartnerIntroduceComponent = ({...props}) : React.ReactElement => {

    return <>
        <Footer />
        
        <Component01 />
        <Component02 />
        <Component03 />
        <Component04 />
        <Component05 />
        
    </>
    
}


export default PartnerIntroduceComponent;