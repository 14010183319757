import React from 'react';
import '../partner.styles.css'
import { CDN } from '../../../keys';

import Logo from '../../../assets/icon/logo/mobile_download_logo.svg';
import Icon01 from '../../../assets/icon/partner/users_table.svg';
import Icon02 from '../../../assets/icon/partner/users_character.svg';
import Icon03 from '../../../assets/icon/partner/animal_table.svg';
import AngleLeft from '../../../assets/icon/common/angle_right_white.svg';


const Component05 = ({...props}) : React.ReactElement => {

    return <div className='partnerIntroScreen05'>

        <div>

            <div className="centerCon">
                <img src={Logo} alt="" className='partnerIntro05Logo' />
            </div>

            <div style={{height : "34.6875vw"}}/>

            <div className="partnerIntro05IconCon">

                <div className="partnerIntro05IconColCon">
                    <img src={Icon01} alt="" className='partnerIntro05Icon' />
                    <div style={{height : "3.1250vw"}} />
                    <p className='partnerIntro05SemiTxt'>펫 돌봄 어플<br/>월 이용자 수</p>
                    <div style={{height : "3.1250vw"}} />
                    <p className='partnerIntro05MainTxt'>1위</p>
                </div>

                <div className="partnerIntro05IconColCon">
                    <img src={Icon02} alt="" className='partnerIntro05Icon' />
                    <div style={{height : "3.1250vw"}} />
                    <p className='partnerIntro05SemiTxt'>반려인<br/>정회원 수</p>
                    <div style={{height : "3.1250vw"}} />
                    <p className='partnerIntro05MainTxt'>약 5만명</p>
                </div>

                <div className="partnerIntro05IconColCon">
                    <img src={Icon03} alt="" className='partnerIntro05Icon' />
                    <div style={{height : "3.1250vw"}} />
                    <p className='partnerIntro05SemiTxt'>월간<br/>돌봄 신청 수</p>
                    <div style={{height : "3.1250vw"}} />
                    <p className='partnerIntro05MainTxt'>약 1천건</p>
                </div>
                

            </div>
        </div>

        <div className='centerCon'>

            <span className='introCom6Txt'>
                펫 돌봄 어플 1위 <span style={{color : "#8B4DFF"}}>우주펫</span>에<br/>
                우리 매장을 등록해 보세요!
            </span>

            <div style={{height : "16.2500vw"}}/>

            {/* <div className="introCom6Btn">
                <span className='introCom6BtnTxt'>지금 무료로 매장 등록하기</span>
                <div style={{width : "1.5625vw"}}/>
                <img src={AngleLeft} alt="" className="introCom6AngleIcon"/>
            </div> */}


        </div>
    </div>
}


export default Component05;