import React from 'react';
import "./main_mobile.styles.css";

import ContentImage from '../../../assets/images/main/mobile/component_07.png'
import Component05Image from '../../../assets/images/main/mobile/component_05.png'
import Component06Image from '../../../assets/images/main/mobile/component_06.png'


const Main05Component = ({...props}) : React.ReactElement => {

    return (
       <>
        <div className="component">
            <div style={{height : 60}}/>
            <img src={ContentImage} alt="컨텐츠" className='componentImage02'/>
        </div>

        <div className="component03">
            <div className="textContainer01">
                <div style={{height : 40}}/>

                <span className="semiTitleText">
                    산책친구
                </span>

                <div style={{height : 6}}/>

                <span className="titleText">
                    우리아이와 잘 맞는<br/>
                    산책친구 만나기
                </span>

                <div style={{height : 12}}/>

                <span className="contentText">
                    우주펫에서 산책 친구를 만나보세요.<br/>
                    거리∙시간대∙보호자 등에 맞게 원하는 친구를 찾을 수<br/>
                    있어요.
                </span>
            </div>

            <img 
                src={Component05Image}
                alt="imageComponent"
                className="componentImage02"
            />
        </div>

        <div className="component">
            <div className="textContainer01">
                <div style={{height : 40}}/>

                <span className="semiTitleText">
                    응가챌린지
                </span>

                <div style={{height : 6}}/>

                <span className="titleText">
                    반려동물 배변용품<br/>
                    쓴 만큼 돌려받기
                </span>

                <div style={{height : 12}}/>

                <span className="contentText">
                    사용한 배변용품을 인증하고 쓴 만큼 받아보세요.<br/>
                    우주펫이 집 앞으로 배변용품을 보내드립니다!
                </span>
            </div>

            <img 
                src={Component06Image}
                alt="imageComponent"
                className="componentImage02"
            />
        </div>
       </>
    )
}

export default Main05Component